<template>
    <vue-final-modal v-model="showModal" classes="modal-container" content-class="modal-content" name="example">
        <div class="imgCarrello">          
                <img src="images/Logo.png">           
        </div>
        <div class="textMsg">
            {{ this.message}}
        </div>      
        <div class="btnContainer">
            <div class="Button CustomColor"><a class="btnCart btnContinue" href="javascript:void(0)" @click="this.closeModal()">{{ risorse("Chiudi") }}</a></div>
            <div class="Button CustomColor"><a class="btnCart" href="javascript:void(0)" @click="this.doLogout()">{{ risorse("LogoutOk") }}</a></div>
        </div>
    </vue-final-modal>
</template>

<script>

import {risorseMixin} from "@/mixin.js";

export default { 
    name: "ModalCart",
    props:{
        //showModal: Boolean,
        message: String

    },
    data(){
        return{
            showModal: null
        }
    },
    mixins:[risorseMixin],
    methods:{
        closeModal: function(){
            this.$vfm.hide('example') 
        },
        doLogout: function(){           
            this.$store.commit("deleteUtente");
            this.$store.commit('deleteAzienda');
            this.$store.commit('deleteCarrello');
            localStorage.clear();
            this.$router.push("/");   
        }
    }
}
</script>
<style>

.modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #fff;
    width: 360px;
}
.imgCarrello{
    width: 100px;
    display: inline-block;
    margin: 0 auto 0 auto;
}

.textMsg{
    padding: 20px 0 20px 0;
    text-align: center;
}

.btnContainer{   
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;    
}

.btnCart{
    width: 100%;
    padding-left:0;
    padding-right:0;
    text-align: center;
}

.btnContinue{
    background: #101820 !important;
    color: #fff !important;
}

</style>