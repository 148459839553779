<template>
    <section class="Profilepage">
        <div class="Container">
            <div class="Box">
                <div class="HeadingGroup">
                    <h1>Profilo</h1>
                    <div class="TxtEditor">
                    </div>
                </div>
            </div>
        </div>
        <div class="Container">
            <div class="Box">
                <div class="Binder Big">
                    <aside class="Aside">
                        <nav class="ProfileMenu">
                            <ul>
                                <li>
                                    <router-link :to="{'name': 'UserDetails'}" active-class="SelectedItem" exact>
                                        {{ this.risorse("DatiUtente") }}</router-link>
                                </li>
                                <li v-if="!this.isB2B">
                                    <router-link :to="{'name': 'GestioneTesserati'}" active-class="SelectedItem" exact>Gestione Tesserati</router-link>
                                </li>
                                <!--<li v-if="!this.isB2B">-->
                                <li>
                                    <router-link :to="{'name': 'StoricoOrdini'}" active-class="SelectedItem" exact>Storico Ordini</router-link>
                                </li> 
                                <!-- <li>
                                    <router-link :to="{'name': 'BillingAddresses'}" active-class="SelectedItem" exact>
                                        {{ this.risorse("IndirizziFatturazione") }}</router-link>
                                </li> -->
                                <li>
                                    <router-link :to="{'name': 'ChangePassword'}" active-class="SelectedItem" exact>
                                        {{ this.risorse("CambiaPassword") }}</router-link>
                                </li>
                                <!-- <li>
                                    <router-link :to="{'name': 'ChangePersonalData'}" active-class="SelectedItem" exact>
                                        {{ this.risorse("ModdatiPers") }}</router-link>
                                </li> -->
                                <li><a href="javascript:void(0)" @click="this.logout()">{{ this.risorse("Logout") }}</a>
                                </li>
                            </ul>
                        </nav>
                    </aside>
                    <div class="Content">
                        <div class="Frame">
                            <!-- Inizio dati utente -->
                            <router-view />
                            <!-- Fine dati utente -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <ModalLogout v-model="this.showModal" :message="this.modalMessage"/>
</template>

<script>

import ModalLogout from "@/components/modals/ModalLogout.vue";
import {risorseMixin, userMixin} from "@/mixin.js";

export default {
    name: "ProfileDetail",
    data(){
        return {
            showModal: false,
            modalMessage: ""
        }
    },
    mixins:[risorseMixin,userMixin],
    methods: {
        logout: function(){
            this.modalMessage = this.risorse("SicuroLogout");
            this.showModal = true;
            
        }
    },
    components: {
        ModalLogout
    }
}
</script>